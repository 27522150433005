<template>
  <div>
    <a-button type="primary" @click="showModal">
      打开
    </a-button>
    <a-modal
      title="Title"
      :width="800"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
        <a-form layout="vertical" :form="form" style="background:red">
            <a-row  :gutter="20">
                <a-col :span="12">
                    <a-form-item label="第1个标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-input
                            v-decorator="['a1', { rules: [{ required: true, message: '请xxx！' }] }]" 
                            style="display:inline-block"
                        />
                    </a-form-item>
                </a-col>
                <a-col  :span="12">
                    <a-form-item :colon="true" label="第2个标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-select>
                            <a-select-item>1</a-select-item>
                            <a-select-item>2</a-select-item>
                            <a-select-item>3</a-select-item>
                            <a-select-item>4</a-select-item>
                        </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col>
                    <a-form-item label="这是标题3:"  :labelCol="labelCol" :wrapperCol="wrapperCol" >
                        <a-select>
                            <a-select-item>

                            </a-select-item>
                    </a-select>
                    </a-form-item>
                </a-col>
            </a-row>
            <!-- <a-form-item label="第2个标题" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-input 
                    v-decorator="['a2', { rules: [{ required: true, message: '请xxx！' }] }]" 
                  
                />
            </a-form-item>
            <a-form-item label="第3个标题" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-input 
                    v-decorator="['a3', { rules: [{ required: true, message: '请xxx！' }] }]" 
                 
                />
            </a-form-item> -->
            <!-- <a-form-item label="第4个标题" :labelCol="labelCol" :wrapperCol="wrapperCol" >
                <a-input 
                    v-decorator="['a4', { rules: [{ required: true, message: '请xxx！' }] }]" 
                 
                />
            </a-form-item> -->
        </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18},
      }
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>
